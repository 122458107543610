/* Reset */

/* set min-width to 320 */
html, body {
  min-width: 320px;
}

/* remove margin and padding */
body, figure, dl, dt, dd, ul, ol, li { 
  margin: 0; 
}
dl, ul, ol {
  padding: 0;
  list-style: none;
}

button, input, select, textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img { 
  border: 0; 
}

/* make images & videos flexible */
img, video { 
  max-width: 100%;
  height: auto;
}
iframe { 
  max-width: 100%; 
}

/* remove the white space under images */
figure img, a > img { 
  vertical-align: bottom; 
}

/* reset form styles */
label { 
  cursor: pointer; 
}
select { 
  -webkit-appearance: none; 
}

/* reset link style */
a {
  text-decoration: none;
  color: inherit;
}

/* Typography */

@font-face {
  font-family: 'cooper_hewittbold';
  src: url('./assets/css/fonts/cooperhewitt-bold-webfont.woff2') format('woff2'),
       url('./assets/css/fonts/cooperhewitt-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'cooper_hewittlight';
  src: url('./assets/css/fonts/cooperhewitt-light-webfont.woff2') format('woff2'),
       url('./assets/css/fonts/cooperhewitt-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'cooper_hewittmedium';
  src: url('./assets/css/fonts/cooperhewitt-medium-webfont.woff2') format('woff2'),
       url('./assets/css/fonts/cooperhewitt-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'cooper_hewittsemibold';
  src: url('./assets/css/fonts/cooperhewitt-semibold-webfont.woff2') format('woff2'),
       url('./assets/css/fonts/cooperhewitt-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body { 
  font-family: cooper_hewittlight, Helvetica, Arial, sans-serif; 
  /* removes the 300ms delay of click events when the user taps the screen */
  touch-action: manipulation; 
}

h1, h2, h3, h4, h5, strong { 
  font-weight: normal; 
}

.h1 {
  font-family: cooper_hewittbold, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.h1-light {
  font-size: 1.4rem;
  font-weight: bold;
}

.h2 {
  font-family: cooper_hewittsemibold, Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
}

.h3 {
  font-family: cooper_hewittsemibold, Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

p {
  font-size: .875rem;
}

p strong {
  font-family: cooper_hewittsemibold, Helvetica, Arial, sans-serif;
  font-weight: normal;
}

address { 
  font-style: normal; 
}

.uppercase { 
  text-transform: uppercase; 
}

.styled-block {
  max-width: 50em;
}

.styled-block p,
.styled-block li {
  font-size: .875rem;
  line-height: 1.7142857;
}

.styled-block p {
  margin: 0 0 1.7142857em;
}

.styled-block p a {
  text-decoration: underline;
}

.styled-block li {
  padding-left: 0.3em;
}

.styled-block ul, 
.styled-block ol { 
  margin-left: 1em; 
}

.styled-block ul { 
  list-style: disc; 
}

.styled-block ol { 
  list-style: number; 
}

.align-right { 
  text-align: right; 
}

.align-center { 
  text-align: center; 
}

/* General */

.visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  margin: -1px;
}

.container {
  max-width: 1100px;
  padding: 0 15px;
  margin: 0 auto;
}

@keyframes topbanner {
  from { transform: translateY(0); }
  to { transform: translateY(-20%); }
}

.top-banner {
  max-height: 90px;
  overflow: hidden;
  margin-bottom: 10px;
}

.top-banner img {
  max-width: none;
  min-width: 100%;
  min-height: 180px;
  -webkit-transform: translateY(-20%);
  -moz-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  -o-transform: translateY(-20%);
  transform: translateY(-20%);
  -webkit-animation: topbanner 2s;
  -moz-animation: topbanner 2s;
  animation: topbanner 2s;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.interactive-link {
  display: inline-block;
  line-height: 1;
}

.interactive-link:after {
  content: '';
  position: relative;
  display: block;
  width: 0%;
  left: 50%;
  margin-top: 3px;
  border-bottom: 2px solid #414141;
  transition: width .25s, left .25s;
}

.interactive-link.active:after,
.interactive-link:hover:after {
  width: 100%;
  left: 0;
}

.btn, .round-icon, .square-icon {
  display: inline-block;
  background: #414141;
  color: #fff;
  transition: background .25s;
}

.round-icon {
  position: relative;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.round-icon.lg {
  width: 36px;
  height: 36px;
}

.round-icon.sm {
  width: 24px;
  height: 24px;
}

.round-icon.facebook:hover {
  background: #3b5998;
}

.round-icon.pinterest:hover {
  background: #bd081c;
}

.round-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.square-icon {
  vertical-align: top;
  width: 14px;
  height: 14px;
  margin-right: 3px;
  font-size: .75rem;
  line-height: 14px;
  text-align: center;
  font-family: cooper_hewittmedium,Helvetica,Arial,sans-serif;
}

.square-icon.lg {
  width: 34px;
  height: 34px;
  line-height: 36px;
  font-size: 1.375rem;
}

.main-content {
  min-height: calc(100vh - 270px);
}

/* Utilities */

.mb-10 {
  margin-bottom: 10px;
}

.mt-20 {
  margin-top: 20px;
}