@media (min-width: 41.875em) {
  .nav-toggle {
    display: none;
  }
}

.nav-toggle {
  position: absolute;
  top: 28px;
  right: 15px;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  background: 0 0;
  cursor: pointer;
  -webkit-transition: -webkit-transform .25s;
  -o-transition: -o-transform .25s;
  -moz-transition: transform .25s,-moz-transform .25s;
  transition: transform .25s;
  transition: transform .25s,-webkit-transform .25s,-moz-transform .25s,-o-transform .25s;
}

.nav-toggle:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  -webkit-transition: background .25s,-webkit-transform .25s;
  -o-transition: background .25s,-o-transform .25s;
  -moz-transition: transform .25s,background .25s,-moz-transform .25s;
  transition: transform .25s,background .25s;
  transition: transform .25s,background .25s,-webkit-transform .25s,-moz-transform .25s,-o-transform .25s;
  background: #eee;
}

.nav-toggle.active:before {
  background: #414141;
}

.nav-toggle span {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  width: 2px;
  height: 18px;
  background: #414141;
}

.nav-toggle span:before {
  left: -6px;
}

.nav-toggle span:after {
  right: -6px;
}

.nav-toggle span:before, 
.nav-toggle span:after {
  width: 2px;
  height: 18px;
  background: #414141;
  content: '';
  position: absolute;
  -webkit-transition: all .25s;
  -o-transition: all .25s;
  -moz-transition: all .25s;
  transition: all .25s;
}

.nav-toggle.active span:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 0;
}

.nav-toggle.active span:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 0;
}

.nav-toggle.active span:before, 
.nav-toggle.active span:after {
  background: #fff;
}