.gallery-header .h1, 
.gallery-header .links {
  display: inline-block;
  margin-right: 10px;
}

.gallery-header .links a,
.gallery-header .links span {
  margin-right: 10px;
}

@media (min-width: 45em) {  
  .gallery-header .link-sibling {
    float: right;
    margin-top: 28px;
  }
}