.svg-logo-wrapper {
  display: none;
}

@media (min-width: 60em) {
  .svg-logo-wrapper:before {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background: #fff;
    animation: logo-animation-wrapper-before 1.5s ease 3s 1 normal forwards;
  }

  @keyframes logo-animation-wrapper-before {
    to { 
      height: 0; 
    }
  }

  .svg-logo-wrapper {
    display: block;
    position: fixed;
    top: 50vh;
    margin-top: -56px;
    max-width: 525px;
    animation: logo-animation-wrapper 1s ease 3s 1 normal forwards;
  }

  @keyframes logo-animation-wrapper {
    to { 
      top: 30px;
      margin-top: 0;
    }
  }

  .svg-logo-wrapper svg {
    position: relative;
    z-index: 10001;
    transform: scale(1.5);
    animation: logo-animation-svg 1s ease 3s 1 normal forwards;
  }

  @keyframes logo-animation-svg {
    to { 
      transform: none; 
    }
  }

  .svg-logo-wrapper svg:first-of-type {
    top: -30px;
    margin-bottom: 1vw;
    animation: logo-animation-svg-first 1s ease 3s normal forwards;
  }

  @keyframes logo-animation-svg-first {
    to {
      transform: none;
      top: 0;
    }
  }

  .svg-logo-wrapper path {
    fill: transparent;
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: logo-animation-path-offset 2.5s ease 0s 1 normal forwards,
              logo-animation-path-fill .5s ease 2.5s 1 normal forwards,
              logo-animation-path-stroke 1s ease 3s 1 normal forwards;
  }

  .svg-logo-wrapper svg:first-of-type path {
    animation: logo-animation-path-offset 1.5s ease 2s 1 normal forwards,
              logo-animation-path-fill .5s ease 2.5s 1 normal forwards,
              logo-animation-path-stroke 1s ease 3s 1 normal forwards;
  }

  @keyframes logo-animation-path-offset {
    to { 
      stroke-dashoffset: 0; 
    }
  }

  @keyframes logo-animation-path-fill {
    to {
      fill: #414141; 
    }
  }

  @keyframes logo-animation-path-stroke {
    to {
      stroke: none;
    }
  }
}