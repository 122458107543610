.site-header {
  position: relative;
  z-index: 200;
  -webkit-box-shadow: 0 3px 20px rgba(0,0,0,.1);
  box-shadow: 0 3px 20px rgba(0,0,0,.1);
}

.branding {
  text-align: center;
}

.branding a {
  display: block;
  position: relative;
  top: 0;
  padding: 30px 0 20px;
  max-width: 524px;
  margin: 0 auto;
}

.branding .logo-sm {
  margin-bottom: 1vw;
  max-width: 39.37%;
}

@media (max-width: 41.875em) {
  .branding {
    text-align: left;
    padding-right: 70px;
  }
  
  .logo-line {
    position: relative;
    z-index: 1;
    background: #fff;
  }
}