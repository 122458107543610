.about-content > li:first-child {
  margin-bottom: 80px;
}

.about-content .h1 span {
  vertical-align: middle;
}

.about-content .round-icon {
  margin-left: 5px;
}

.about-content .round-icon:first-of-type {
  margin-left: 15px;
}

@media (max-width: 49.9375em) {
  .about-content .h1 {
    text-align: center;
  }
}

@media (min-width: 50em) {
  .sidebar-left {
    float: left;
    margin-right: 30px;
  }

  .sidebar-right {
    float: right;
    margin-left: 30px;
  }
  
  .sidebar-left+div, .sidebar-right+div {
    overflow: hidden;
  }
}