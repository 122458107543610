.slider-artwork {
  position: relative;  
}

.slider-artwork .tns-outer {
  overflow: hidden;
}

.slider-artwork .tns-controls {
  position: absolute;
  z-index: 10;
  right: 0;
}

.slider-artwork .tns-controls button {
  padding: 0;
  height: auto;
  border: 0;
  background: transparent;
}

.slider-artwork .tns-controls button:focus {
  outline: none;
}

.slider-artwork .tns-controls button:last-child {
  margin-left: 20px;
}

.slider-artwork .tns-controls:focus {
  outline: none;
}

.slider-artwork .square-icon { 
  margin: 0; 
}

.slider-artwork .h2 { 
  padding-right: 100px; 
}