.site-footer {
  margin: 30px 0 20px;
  text-align: center;
}

.site-footer.has-divider {
  padding-top: 1rem;
  border-top: 1px solid #414141;
}

.site-footer--inverted {
  color: #fff;
  position: relative;
  z-index: 100;
  margin: -40px 0 0;
  padding-top: 0;
  border: 0;
}

.site-footer .copyright {
  font-size: .875rem;
}

.site-footer .copyright span:first-child {
  margin-right: 3px;
}