.slider-home {
  position: relative;
  overflow: hidden;
}

.slider-home .tns-nav {
  text-align: center;
  position: absolute;
  z-index: 100;
  width: 100%;
  bottom: 54px;
}

.slider-home .tns-nav button {
  display: inline-block;
  border: 2px solid #fff;
  width: 11px;
  height: 11px;
  margin: 0 7px;
  padding: 0;
  border-radius: 50%;
  background: 0 0;
  outline: none;
}

.slider-home .tns-nav .tns-nav-active {
  background: #fff;
}

.slider-home .tns-slide-active img {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.slider-home .tns-item img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 0%;
  object-position: 50% 0%;
  max-width: none;
  width: 100%;
}
.slider-home .tns-item img {
  height: 100vh;
  /* width: auto; */
}
.slider-home img {
  width: 100%;
  vertical-align: bottom;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: -webkit-transform 7s;
  -o-transition: -o-transform 7s;
  -moz-transition: transform 7s,-moz-transform 7s;
  transition: transform 7s;
  transition: transform 7s,-webkit-transform 7s,-moz-transform 7s,-o-transform 7s;
}