.artwork-gallery {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
}

.artwork-gallery-item {
  flex: 1 0 21%;
  min-width: 165px;
  max-width: 260px;
  transition: opacity 0.4s, transform 0.4s;
  transition-timing-function: ease-out;
}

.artwork-gallery-item.fade-below {
  opacity: 0.8;
  transform: translateY(40px);
}

.artwork-gallery-item.fade-above {
  opacity: 0.8;
  transform: translateY(-40px);
}

.artwork-gallery-item .h2 {
  margin: 10px 0 0 0;
}

.artwork-gallery-item p {
  margin: 7px 0 30px 0;
}
