.not-fount-page {
  text-align: center;
}

.not-fount-page .text-404 {
  font-size: 170px;
}

.not-fount-page .text-400-description {
  font-size: 50px;
}

.not-fount-page img {
  margin-top: 50px;  
}