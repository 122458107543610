.artwork-thumbnail {
  margin-right: 20px;
  min-width: 165px;
}

.artwork-thumbnail > a {
  position: relative;
  display: block;
  padding-bottom: 200%;
  overflow: hidden;
}

.artwork-thumbnail img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 2s, transform 2s;
}

.artwork-thumbnail img:hover {
  filter: grayscale(0) saturate(1.1) contrast(1.1);
  transform: scale(1.03);
}
