.site-nav {
  text-align: center;
  overflow: hidden;
}

.site-nav ul {
  text-align: justify;
  margin: 5px 0 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.site-nav li {
  display: inline-block;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: .9375rem;
  line-height: 1;
}

.site-nav a {
  padding: 12px 0 0;
}

@media (max-width: 41.875em) {  
  .site-nav {
    position: absolute;
    -webkit-transform: translateY(-110%);
    -moz-transform: translateY(-110%);
    -ms-transform: translateY(-110%);
    -o-transform: translateY(-110%);
    transform: translateY(-110%);
    border-top: 2px solid #414141;
    background: #fff;
    -webkit-transition: -webkit-transform .25s;
    -o-transition: -o-transform .25s;
    -moz-transition: transform .25s,-moz-transform .25s;
    transition: transform .25s;
    transition: transform .25s,-webkit-transform .25s,-moz-transform .25s,-o-transform .25s;
  }
  
  .site-nav.show {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    width: 100%;
  }
}