.overlay {  
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.loader {
  position: absolute;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
}