.contact-page .icon-container {
  margin-bottom: 10px;
}

.contact-page .icon-container .round-icon {  
  margin-right: 11px;
}

.contact-page .image-container {
  margin-top: 50px;
  text-align: center;
}